<template>
    <div class="Header">
        <v-app-bar color="disiDarkblueLogo" dark height="60px">
            <v-toolbar-title>
                <img src="@/assets/Ischi-logo-weiss-22.svg" alt="Charles Ischi Logo" title="Charles Ischi Logo">
            </v-toolbar-title>
            <router-link to="/stations-overview" alt="Home Button" title="Home Button"
                         class="home-button pl-2 pr-2 mr-4" v-if="this.$route.name != 'Login'">
                <img src="@/assets/home_weiss.svg">
            </router-link>
            <v-spacer/>
            <v-badge
                v-if="errorlist.length > 0 && this.$route.name != 'Login'"
                overlap
                :content="unreadErrors"
                color="white"
            >
                <button v-if="errorlist.length > 0 && this.$route.name != 'Login'" class="error-btn"
                        :class="{'open': showErrorList}" @click.stop="showErrorList = !showErrorList"></button>
            </v-badge>
            <button class="menu-open" @click.stop="drawer = !drawer; showErrorList = false"
                    v-if="this.$route.name != 'Login'"></button>
        </v-app-bar>

        <v-container v-if="showErrorList" id="errorList" class="d-flex justify-center align-center">
            <v-list-item-group>
                <router-link :to="error.linkTo" class="router-link" :class="{'errorStatusDone': error.errorState === false}" v-for="error in errorlist" :key="error.id">
                    <v-list-item class="" @click="showErrorList = false; clearError(error.id)" :disabled="!error.linkTo">
                        <v-list-item-content>
                            <v-list-item-title class="d-flex align-center justify-space-between">
                                <p>{{ error.message }}</p>
                                <p class="linkText">{{ error.linkText }}</p>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
            </v-list-item-group>
        </v-container>

        <v-navigation-drawer v-model="drawer" temporary absolute right dark color="disiDarkblueLogo" width="340px">
            <button class="menu-close" @click.stop="drawer = !drawer"></button>
            <v-list nav class="pt-15">
                <v-list-item-group v-model="group" active-class="disiDarkblueLogo">
                    <router-link to="/adj-cal" :event="checkUserRights('adjCal') ? 'click' : ''">
                        <v-list-item class="pl-8 pr-8" active-class="disiDarkblueLogo"
                                     :class="{'disiIrisblue': this.$route.fullPath === '/adj-cal' || this.$route.fullPath === '/adjustment' || this.$route.fullPath === '/adjustment-reports' || this.$route.fullPath === '/adjustment-height' || this.$route.fullPath === '/adjustment-position' || this.$route.fullPath === '/calibration' || this.$route.fullPath === '/calibration-reports' || this.$route.fullPath === '/calibration-height' || this.$route.fullPath === '/calibration-temp' || this.$route.fullPath === '/calibration-position' || this.$route.fullPath === '/calibration-freq'}">
                            <v-list-item-content class="calibration d-flex align-center"
                                                 :class="{'disabled': !checkUserRights('adjCal')}">
                                <v-list-item-title>{{ $t('adjCal.adjCal') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>

                    <router-link to="/methods" :event="checkUserRights('methods') ? 'click' : ''">
                        <v-list-item class="pl-8 pr-8" active-class="disiDarkblueLogo"
                                     :class="{'disiIrisblue': this.$route.fullPath === '/methods' || this.$route.fullPath === '/methods-edit'}">
                            <v-list-item-content class="method d-flex align-center"
                                                 :class="{'disabled': !checkUserRights('methods')}">
                                <v-list-item-title>{{ $t('methods.methods') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>

                    <router-link to="/products" :event="checkUserRights('products') ? 'click' : ''">
                        <v-list-item class="pl-8 pr-8" active-class="disiDarkblueLogo"
                                     :class="{'disiIrisblue': this.$route.fullPath === '/products' || this.$route.fullPath === '/products-edit'}">
                            <v-list-item-content class="product d-flex align-center"
                                                 :class="{'disabled': !checkUserRights('products')}">
                                <v-list-item-title>{{ $t('products.products') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>

                    <router-link to="/report" :event="checkUserRights('reports') ? 'click' : ''">
                        <v-list-item class="pl-8 pr-8" active-class="disiDarkblueLogo"
                                     :class="{'disiIrisblue': this.$route.fullPath === '/report' || this.$route.fullPath === '/report-testinfo' || this.$route.fullPath === '/report-method' || this.$route.fullPath === '/report-results' || this.$route.fullPath === '/report-statistics' || this.$route.fullPath === '/report-graph'}">
                            <v-list-item-content class="report d-flex align-center"
                                                 :class="{'disabled': !checkUserRights('reports')}">
                                <v-list-item-title>{{ $t('report.reports') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>

                    <router-link to="/system" :event="checkUserRights('system') ? 'click' : ''">
                        <v-list-item class="pl-8 pr-8" active-class="disiDarkblueLogo"
                                     :class="{'disiIrisblue': this.$route.fullPath === '/system' || this.$route.fullPath === '/calsettings' || this.$route.fullPath === '/media' || this.$route.fullPath === '/media-edit' || this.$route.fullPath === '/printer' || this.$route.fullPath === '/pdf-export' || this.$route.fullPath === '/stations' || this.$route.fullPath === '/backup' || this.$route.fullPath === '/backup-restore' || this.$route.fullPath === '/user-management' || this.$route.fullPath === '/users' || this.$route.fullPath === '/users-edit' || this.$route.fullPath === '/usergroups' || this.$route.fullPath === '/usergroups-edit' || this.$route.fullPath === '/service' || this.$route.fullPath === '/network' || this.$route.fullPath === '/share' || this.$route.fullPath === '/share-edit' || this.$route.fullPath === '/firmwareupdate' || this.$route.fullPath === '/system-info' || this.$route.fullPath === '/heater'}">
                            <v-list-item-content class="system d-flex align-center"
                                                 :class="{'disabled': !checkUserRights('system')}">
                                <v-list-item-title>{{ $t('system.system') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>

                    <v-list-item class="pl-8 pr-8" active-class="disiDarkblueLogo" v-if="this.$route.fullPath != '/login'" @click="logout">
                        <v-list-item-content class="logout d-flex align-center">
                            <v-list-item-title>{{ $t('login.logout') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
            <router-link to="/change-password">
                <p class="user" v-if="this.$route.fullPath != '/login'">
                    {{ $t('login.user') }}: {{ activeUser.username }}
                </p>
            </router-link>
        </v-navigation-drawer>
    </div>
</template>

<script>
import {mapState} from "vuex";
import mixins from "@/mixins/mixins";

export default {
    name: "Header",
    data: () => ({
        drawer: false,
        showErrorList: false,
        group: null,
    }),
    computed: {
        ...mapState([
            'activeUser',
            'errorlist',
        ]),
        unreadErrors(){
            let allErrors = this.errorlist;
            let unreadErrors = 0;
            allErrors.forEach(error => {
                if (error.errorState === true){
                    unreadErrors++;
                }
            })
            return unreadErrors;
        },
    },
    methods: {
        logout() {
            this.$store.state.errorListLoop == false;
            if(this.$store.state.cancelToken != undefined){
                this.$store.state.cancelToken.cancel("logout");
                this.$store.state.cancelToken = undefined;
            }
            this.$store.dispatch('postAxiosNoSetter', ['/admin/user/logout', {id: this.activeUser.id}]);
            this.$store.commit('resetLogin');
            this.$store.commit('removeActiveUser');
            localStorage.removeItem('token');
            this.$router.push('login');
        },
        getErrorMessages() {
            if(this.$store.state.errorListLoop == true){
                this.$store.dispatch('postAxiosCancelSetter', ['/admin/device/errorlist', {waitForError: true}, 'errorlist'])
                    .then(response => {
                        if (response.status === 200 && localStorage.token != undefined) {
                            return this.getErrorMessages();
                        }else{
                            this.$store.state.errorListLoop = false;
                        }
                    });
            }
        },
        clearError(id){
            this.$store.dispatch('postAxiosNoSetter', ['/admin/device/clearError', {id: id}]);
        },
        watcher() {
            this.$watch('$store.state.activeUser', function () {
                if (this.$store.state.activeUser.accessToken) {
                    this.$store.dispatch('postAxiosSetter', ['/admin/device/errorlist', {waitForError: false}, 'errorlist'])
                        .then(response => {
                            if (response.status === 200) {
                                if(this.$store.state.errorListLoop != true){    // Don't use "... == false" - the variable could also be undefined
                                    this.$store.state.errorListLoop = true;
                                    return this.getErrorMessages();
                                }
                            }
                        });
                }
            });
        }
    },
    created() {
        this.watcher();
    },
    mixins: [mixins],
    watch: {
        group() {
            this.drawer = false
        },
    },
}
</script>

<style scoped lang="scss">

.Header {
    z-index: 100;

    .v-toolbar__title {
        width: 150px;
        max-height: 52px;
        padding-top: 2px;
        padding-left: 2px;
        display: flex;
        align-items: center;

        a {
            width: 100%;
            height: 100%;
            display: flex;
            align-content: center;
        }

        img {
            max-height: 36px;
        }
    }

    .home-button {
        height: 35px;
        width: 50px;
    }

    .error-btn {
        width: 32px;
        height: 32px;
        position: relative;
        overflow: hidden;

        &::before {
            content: '!';
            position: absolute;
            top: 6px;
            left: 0;
            width: 32px;
            text-align: center;
            font-weight: bold;
            z-index: 5;
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 532px;
            background-image: linear-gradient(70deg, $disiOrange, $disiOrange, $disiOrange, $disiRed, $disiOrange, $disiOrange, $disiOrange);
            animation: error-btn-bg infinite ease-out 8s;
        }

        &.open {
            &::after {
                background-color: $disiRed;
                background-image: unset;
                animation: none;
            }
        }
    }

    @keyframes error-btn-bg {
        from {
            left: 0
        }
        to {
            left: -500px
        }
    }

    .menu-open {
        width: 40px;
        height: 60px;
        background-image: url("../assets/navigation_weiss.svg");
        background-size: 40px;
        background-position: center;
        background-repeat: no-repeat;
        margin-left: 40px;

        &:focus {
            outline: none;
        }
    }

    .menu-close {
        position: absolute;
        right: 0;
        width: 70px;
        height: 60px;
        padding: 8px;
        background-image: url("../assets/abort_weiss.svg");
        background-size: 20px;
        background-position: center;
        background-repeat: no-repeat;

        &:focus {
            outline: none;
        }
    }
}

#errorList {
    position: fixed;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($disiDarkblueLogo, 0.9);
    border: 10px solid $disiRed;
    max-width: 1024px;
    padding: 50px 0;

    .v-list-item-group {
        max-height: 100%;
        overflow: scroll;
    }

    .theme--light.v-list-item[data-v-61dd7a3d]:not(.v-list-item--active):not(.v-list-item--disabled) {
        color: white !important;
    }

    .router-link {
        &.errorStatusDone{
            .v-list-item {
                opacity: 0.5;
            }
        }
        .v-list-item {
            width: 800px;
            padding: 0 20px;
            margin-bottom: 10px;
             background-color: $disiIrisblue;

            .v-list-item__content {
                padding-top: 30px;
                padding-bottom: 30px;

                p {
                    color: white;
                    white-space: break-spaces;
                    font-weight: 500;
                }

                .v-list-item__title {
                    max-width: 100%;
                }

                .linkText {
                    font-size: 0.85rem;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    max-width: 400px;
                    margin-left: 30px;
                    text-align: right;
                    color: $disiDarkblue;
                }
            }
        }

        &:last-of-type .v-list-item {
            border-bottom: none;
        }
    }
}

.v-btn--icon.v-size--default {
    margin-right: 0 !important;

    .v-icon.fa-bars {
        font-size: 30px;
        transform: scale(1.5, 1);
    }
}

.v-navigation-drawer--close {
    width: 0 !important;
}

.v-navigation-drawer__content {
    .v-list.v-list--nav.theme--dark {
        padding: 0;

        a {
            color: white;
            text-decoration: none;
        }

        .v-list-item {
            min-height: 70px;

            &::before {
                background-color: $disiOrange;
            }

            &--active::before {
                opacity: 0;
            }

            &:hover::before {
                opacity: 0;
            }

            &:hover {
                background-color: $disiOrange;
            }

            .v-list-item__title {
                z-index: 1;
                flex-basis: 50%;
                font-weight: 500;
                font-size: 1.2rem;
            }
        }
    }
}

.v-navigation-drawer__content .v-list-item__content {
    &::before {
        content: "";
        width: 50px;
        height: 35px;
        margin-right: 10px;
        background-size: contain;
        background-position: center;
    }

    &.disabled {
        opacity: 0.3;
    }

    &.calibration::before {
        height: 32px;
        margin-bottom: 3px;
        background-image: url("../assets/dot-circle-regular_weiss.svg");
    }

    &.method::before {
        height: 32px;
        background-image: url("../assets/method_weiss.svg");
    }

    &.product::before {
        width: 40px;
        margin-right: 20px;
        background-image: url("../assets/productTest_weiss.svg");
    }

    &.report::before {
        background-image: url("../assets/report_weiss.svg");
        margin-bottom: 5px;
    }

    &.system::before {
        height: 40px;
        background-image: url("../assets/system_weiss.svg");
    }

    &.logout::before {
        height: 32px;
        margin-bottom: 5px;
        background-image: url("../assets/logout_weiss.svg");
    }
}

.v-application p.user {
    position: absolute;
    bottom: 32px;
    right: 32px;
    font-size: 0.85rem;
    color: $disiIrisblue;
    font-weight: 500;
}

</style>