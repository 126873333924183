<template>
    <div :class="keyboardClass"></div>
</template>

<script>
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";
import {mapMutations, mapState} from "vuex";

export default {
    name: "SimpleKeyboard",
    props: {
        keyboardClass: {
            default: "simple-keyboard",
            type: String
        },
        inputName: {
            type: String
        },
        onlyNumbers: {
            type: Boolean
        }
    },
    data: () => ({
        keyboard: null,
    }),
    mounted() {
        this.keyboard = new Keyboard({
            onChange: this.onChange,
            onKeyPress: this.onKeyPress,
            layoutName: this.$store.state.keyboardLang,
            layout: {
                en: [
                    "q w e r t y u i o p",
                    "a s d f g h j k l",
                    "{shift} z x c v b n m {bksp}",
                    "{num} {space} , . {enter}"
                ],
                enShift: [
                    "Q W E R T Y U I O P",
                    'A S D F G H J K L',
                    "{shiftactivated} Z X C V B N M {bksp}",
                    "{num} {space} , . {enter}"
                ],
                enNum: [
                    "1 2 3 4 5 6 7 8 9 0",
                    '? ! " + $ % & @ # *',
                    "{shiftinactive} = - _ / ' ( ) {bksp}",
                    "{default} {space} : . {enter}"
                ],
                euro: [
                    "q w e r t z u i o p",
                    "a s d f g h j k l",
                    "{shift} y x c v b n m {bksp}",
                    "{EuroNum} {EuroSpec} {space} , . {enter}"
                ],
                euroShift: [
                    "Q W E R T Z U I O P",
                    'A S D F G H J K L',
                    "{shiftactivated} Y X C V B N M {bksp}",
                    "{EuroNum} {EuroSpec} {space} , . {enter}"
                ],
                euroNum: [
                    "1 2 3 4 5 6 7 8 9 0",
                    '? ! " + $ % & @ # *',
                    "{shiftinactive} = - _ / ' ( ) {bksp}",
                    "{default} {EuroSpec} {space} : . {enter}"
                ],
                euroSpec: [
                    "ä à á â ë è é ê ï ì í î",
                    'ö ò ó ô ü ù ú û ç ñ "',
                    "{shift} ? ! - _ / ( ) # * {bksp}",
                    "{EuroNum} {default} {space} , . {enter}"
                ],
                euroSpecShift: [
                    "Ä À Á Â Ë È É Ê Ï Ì Í Î",
                    'Ö Ò Ó Ô Ü Ù Ú Û Ç Ñ "',
                    "{shiftactivated} ? ! - _ / ( ) # * {bksp}",
                    "{EuroNum} {default} {space} , . {enter}"
                ],
                numBlock: [
                    "7 8 9 {bksp}",
                    '4 5 6 -',
                    "1 2 3 .",
                    "0 {enter}"
                ],
            },
            display: {
                "{shift}": " ",
                "{shiftactivated}": " ",
                "{shiftinactive}": " ",
                "{enter}": " ",
                "{bksp}": " ",
                "{space}": " ",
                "{default}": "ABC",
                "{num}": "123",
                "{EuroNum}": "123",
                "{EuroSpec}": "âéö",
            },
            buttonTheme: [
                {
                    class: "function-btn",
                    buttons: "{shift} {shiftSpec} {enter} {bksp} {default} {num} {EuroNum} {EuroSpec}"
                },
                {
                    class: "function-btn-active",
                    buttons: "{shiftactivated}"
                },
                {
                    class: "function-btn-inactive",
                    buttons: "{shiftinactive}"
                },
                {
                    class: "button-shift",
                    buttons: "{shiftactivated} {shiftinactive} {shiftSpecActivated}"
                },
                {
                    class: "hide-keyboard",
                    buttons: "{enter}"
                },
            ]
        });
        this.keyboard.setInput(this.$store.state[this.inputName]);
    },
    computed: {
        ...mapState([
            'keyboardLang',
        ]),
    },
    methods: {
        ...mapMutations([
            'textinput',
        ]),
        onChange(input) {
            let storeInput = {
                name: this.inputName,
                value: input,
            }
            this.$store.commit('textinput', storeInput)
        },
        onKeyPress(button) {
            this.$emit("onKeyPress", button);
            /**
             * Handle toggles
             */
            if (button.includes("{") && button.includes("}")) {
                this.handleLayoutChange(button);
            }
        },
        handleLayoutChange(button) {
            let layoutName;
            let currentLayout = this.keyboard.options.layoutName;
            switch (button) {
                case "{shift}":
                    if (currentLayout == 'en'){
                        layoutName = "enShift"
                    } else if (currentLayout == 'euro'){
                        layoutName = "euroShift"
                    } else if (currentLayout == 'euroSpec'){
                        layoutName = "euroSpecShift"
                    }
                    break;
                case "{shiftactivated}":
                    if (currentLayout == 'enShift'){
                        layoutName = "en"
                    } else if (currentLayout == 'euroShift'){
                        layoutName = "euro"
                    } else if (currentLayout == 'euroSpecShift'){
                        layoutName = "euroSpec"
                    }
                    break;
                case "{num}":
                    if (currentLayout == 'en' || currentLayout == 'enShift'){
                        layoutName = "enNum"
                    }
                    break;
                case "{EuroNum}":
                    if (currentLayout == 'euro' || currentLayout == 'euroShift' || currentLayout == 'euroSpec' || currentLayout == 'euroSpecShift'){
                        layoutName = "euroNum"
                    }
                    break;
                case "{EuroSpec}":
                    if (currentLayout == 'euro' || currentLayout == 'euroNum'){
                        layoutName = "euroSpec"
                    } else if (currentLayout == 'euroShift'){
                        layoutName = "euroSpecShift"
                    }
                    break;
                case "{default}":
                    if (currentLayout == 'enNum') {
                        layoutName = "en"
                    } else if (currentLayout == 'euroNum' || currentLayout == 'euroSpec') {
                        layoutName = "euro"
                    } else if (currentLayout == 'euroSpecShift') {
                        layoutName = "euroShift"
                    }
                    break;
                default:
                    break;
            }

            if (layoutName) {
                this.keyboard.setOptions({
                    layoutName: layoutName
                });
            }
        },
        setLang(){
            this.keyboard.setOptions({
                layoutName: this.$store.state.keyboardLang,
            });
        },
        resetInput(){
            this.keyboard.input.default = null;
        }
    },
    watch: {
        input(input) {
            this.keyboard.setInput(input);
        },
        inputName() {
            let pathParts = this.inputName.split('-');
            if (pathParts.length == 1){
                this.keyboard.setInput(this.$store.state[pathParts[0]]);
            } else if (pathParts.length == 2){
                this.keyboard.setInput(this.$store.state[pathParts[0]][pathParts[1]]);
            } else if (pathParts.length == 3){
                this.keyboard.setInput(this.$store.state[pathParts[0]][pathParts[1]][pathParts[2]]);
            }
        },
        onlyNumbers(){
            if (this.onlyNumbers === true){
                this.keyboard.setOptions({
                    layoutName: 'numBlock',
                });
            } else {
                this.keyboard.setOptions({
                    layoutName: this.$store.state.keyboardLang,
                });
            }
        }
    }
};
</script>

<style scoped lang="scss">
</style>