import {mapState, mapGetters} from 'vuex'
import stateJS from '../store/state.js';

export default{
    computed: {
        ...mapGetters([
            'userRights',
        ]),
        ...mapState([
            'systemsettings',
        ]),
    },
    methods: {
        /**
         * format timestamp to 00:00:00
         * @param value (timestamp)
         * @returns {string}
         */
        timeFormat(value){
            var hours = Math.floor(value / 3600);
            var minutes = Math.floor((value - hours*3600) / 60);
            var seconds = value % 60;

            let dHours = (hours > 9 ? hours : '0' + hours);
            let dMins = (minutes > 9 ? minutes : '0' + minutes);
            let dSecs = (seconds > 9 ? seconds : '0' + seconds);

            return dHours + ':' + dMins + ':' + dSecs
        },

        /**
         * format 00:00:00 to timestamp
         * @param string
         * @returns {number}
         */
        timestampFormat(string){
            let hours = string.split(':')[0];
            let minutes = string.split(':')[1];
            let seconds = string.split(':')[2];

            let hoursInSeconds = hours * 3600;
            let minutesInSeconds = minutes * 60;

            let timestamp = hoursInSeconds + minutesInSeconds + parseInt(seconds);
            return timestamp;
        },

        /**
         * Format date to user defined format
         * @param string
         * @returns {string}
         */
        userDateFormat(string){
            let formattedDate, date;
            if (string instanceof Date){
                date = string;
            } else {
                date = new Date(string);
            }
            if (stateJS.systemsettings.dateFormat === 'DD.MM.YYYY hh:mm:ss'){
                formattedDate = date.toLocaleDateString('de-DE',{day: "2-digit", month: "2-digit", year: "numeric"});
            } else if (stateJS.systemsettings.dateFormat === 'MM/DD/YYYY hh:mm:ss'){
                formattedDate = date.toLocaleDateString('en-US',{day: "2-digit", month: "2-digit", year: "numeric"});
            } else {
                formattedDate = string;
            }
            return formattedDate;
        },

        /**
         * Format date to isoString for datepicker YYYY-MM-DD
         * @param string
         * @returns {string|*}
         */
        datePickerDate(string){
            let isoDate, dateElem;
            if (string instanceof Date){
                let month = string.getMonth() + 1;
                if (month < 10 && !month.toString().includes('0')){
                    month = `0${month}`
                }
                let day = string.getDate();
                if (day < 10 && !day.toString().includes('0')){
                    day = `0${day}`
                }
                isoDate = `${string.getFullYear()}-${month}-${day}`
            } else {
                if (stateJS.systemsettings.dateFormat === 'YYYY-MM-DD hh:mm:ss' && string.includes('-')){
                    return string;
                } else if (string.includes('.')) {
                    dateElem = string.split('.');
                    let month = dateElem[1];
                    if (month < 10 && !month.toString().includes('0')){
                        month = `0${month}`
                    }
                    let day = dateElem[0];
                    if (day < 10 && !day.toString().includes('0')){
                        day = `0${day}`
                    }
                    isoDate = `${dateElem[2]}-${month}-${day}`
                } else if (string.includes('/')) {
                    dateElem = string.split('/');
                    let month = dateElem[0];
                    if (month < 10 && !month.includes('0')){
                        month = `0${month}`
                    }
                    let day = dateElem[1];
                    if (day < 10 && !day.includes('0')){
                        day = `0${day}`
                    }
                    isoDate = `${dateElem[2]}-${month}-${day}`
                }
            }
            return isoDate;
        },

        /**
         * check if string is in userRights-Array of logged in user
         * @param string
         * @returns {boolean}
         */
        checkUserRights(string){
            if (this.userRights && this.userRights.includes(string)){
                return true;
            } else {
                return false;
                // return true; //To deactivate user rights check
            }
        },


    }
}