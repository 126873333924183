import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#3D6D7D',
                disiDarkblueLogo: '#2B6071',
                disiDarkblue: '#3D6D7D',
                disiDarkblueMedium: '#638B99',
                $disiDarkblueLight: '#8FAAB3',
                disiIrisblue: '#40BEBE',
                disiLightIrisblue: '#cfe2e2',
                disiGrey: '#3C3C3C',
                disiBluegreyDark: '#9EA7B3',
                disiBluegreyMedium: '#D1D7E0',
                disiBluegreyLight: '#f1f2f2',
                disiOrange: '#FF8413',
                disiRed: '#E73F3F',
            },
            dark: {
                primary: '#3D6D7D',
                disiDarkblueLogo: '#2B6071',
                disiDarkblue: '#3D6D7D',
                disiDarkblueMedium: '#638B99',
                $disiDarkblueLight: '#8FAAB3',
                disiIrisblue: '#40BEBE',
                disiLightIrisblue: '#cfe2e2',
                disiGrey: '#3C3C3C',
                disiBluegreyDark: '#9EA7B3',
                disiBluegreyMedium: '#D1D7E0',
                disiBluegreyLight: '#f1f2f2',
                disiOrange: '#FF8413',
                disiRed: '#E73F3F',
            },
        },
    },
});