import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import store from '../src/store/store';
import router from './router/router';
import i18n from './i18n';
import mixins from './mixins/mixins';
import confirm from './plugins/confirm'

//This option suppresses all Vuetify logs and warnings.
Vue.config.productionTip = false;
Vue.config.devtools = true; //TODO: disable for live -> dont commit

Vue.prototype.$confirm = confirm;

new Vue({
    vuetify,
    store,
    router,
    i18n,
    mixins: mixins,
    render: h => h(App)
}).$mount('#app')
