export const stateJS = {
    //general
    language: 'en',
    keyboardLang: 'en',
    activeStationId: 1,
    activeUser: {
    },

    errorScreen: {
        status: false,
        message: '',
        stationRed: false,
        erroredStationId: null,
    },

    errorlist: [],
    startup:{
        progress: 0,
        version: '',
        date: '01.01.2021',
        time: '00:0',
        activity: "",
        messages: []
    },

    batchlist: [],

    // status codes runningTest.status.status
    //gruen ready after restart  >> 1
    //gruen ready  >> 2  -> wie finished
    //gruen running  >> 3
    //gruen finished  >> 4 -> wie ready
    //gruen first part of combination test finished  >> 5
    //orange pretest complete >> 6
    //gruen pretest running >> 7
    //orange auto heating after test start  >> 9
    //orange auto cooling after test start  >> 10
    //orange manual heating  >> 11
    //orange manual cooling  >> 12
    //orange paused  >> 13
    //orange waiting for user input >> 14 (after manuel test or without disc)
    //rot failed  >> 100
    //rot Test aborted by system >> 101
    //rot connectionLost  >> 102
    //rot noBeaker  >> 103
    //grau not connected >> 0
    stations: [
        {
            // station 1,
            connected: '',
            heater: {
                status: false,
                temp: ""
            },
            runningTest: {
                status: {
                    status: 0,
                    message: ''
                },
                testType: '',
                testId: '',
                productName: '',
                plannedTime: 0,
                batch: '',
                withoutDisc: false,
                manual: false,
                testTime: 0,
                tubeList: [
                    {
                        disintegration: 0,
                        time: 0
                    },
                    {
                        disintegration: 0,
                        time: 0
                    },
                    {
                        disintegration: 0,
                        time: 0
                    },
                    {
                        disintegration: 0,
                        time: 0
                    },
                    {
                        disintegration: 0,
                        time: 0
                    },
                    {
                        disintegration: 0,
                        time: 0
                    }
                ],
            }
        },
        {
            // station 2,
            connected: '',
            heater: {
                status: false,
                temp: ""
            },
            runningTest: {
                status: {
                    status: 0,
                    message: ''
                },
                testType: '',
                testId: '',
                productName: '',
                plannedTime: 0,
                batch: '',
                withoutDisc: false,
                manual: false,
                testTime: 0,
                tubeList: [
                    {
                        disintegration: 0,
                        time: 0
                    },
                    {
                        disintegration: 0,
                        time: 0
                    },
                    {
                        disintegration: 0,
                        time: 0
                    },
                    {
                        disintegration: 0,
                        time: 0
                    },
                    {
                        disintegration: 0,
                        time: 0
                    },
                    {
                        disintegration: 0,
                        time: 0
                    }
                ],
            }
        },
        {
            // station 3,
            connected: '',
            heater: {
                status: false,
                temp: ""
            },
            runningTest: {
                status: {
                    status: 0,
                    message: ''
                },
                testType: '',
                testId: '',
                productName: '',
                plannedTime: 0,
                batch: '',
                withoutDisc: false,
                manual: false,
                testTime: 0,
                tubeList: [
                    {
                        disintegration: 0,
                        time: 0
                    },
                    {
                        disintegration: 0,
                        time: 0
                    },
                    {
                        disintegration: 0,
                        time: 0
                    },
                    {
                        disintegration: 0,
                        time: 0
                    },
                    {
                        disintegration: 0,
                        time: 0
                    },
                    {
                        disintegration: 0,
                        time: 0
                    }
                ],
            }
        },
        {
            // station 4,
            connected: '',
            heater: {
                status: false,
                temp: ""
            },
            runningTest: {
                status: {
                    status: 0,
                    message: ''
                },
                testType: '',
                testId: '',
                productName: '',
                plannedTime: 0,
                batch: '',
                withoutDisc: false,
                manual: false,
                testTime: 0,
                tubeList: [
                    {
                        disintegration: 0,
                        time: 0
                    },
                    {
                        disintegration: 0,
                        time: 0
                    },
                    {
                        disintegration: 0,
                        time: 0
                    },
                    {
                        disintegration: 0,
                        time: 0
                    },
                    {
                        disintegration: 0,
                        time: 0
                    },
                    {
                        disintegration: 0,
                        time: 0
                    }
                ],
            }
        },
    ],

    quickTest: {
        batch: 0,  //default
        newBatch: '',
        testingTime: '00:00:00',
        media: 0,
        basket: 0,
        combinationTest: false, //default
        threshold: 0.3, //default
        temperatureStatus: true, //default
        temperatureMin: '35', //default
        temperatureMax: '39',//default
        fastDisintegration: false,
        withoutDisc: false,
        manual: false,
    },
    productTest: {
        product: null,
        method: null,
        batch: 0, //default
        newBatch: '',
    },

    //Adjustment & Calibration
    adjustmentlist: [],
    adjustment: {
        height: {},
        basketPosition: {}
    },
    adjustmentValue: {
        discId: '',
        temperature: null,
    },
    progressBar: {
        activity: "",
        progress: 0
    },
    calibrationlist: [],
    calibration: {
        height: {},
        temperature: {},
        basketPosition: {},
        frequency: {},
    },
    calibrationHeightReport:{},
    calibrationTempReport:{
        success: false,
        referenceTemperature: null,
        autoMeasuredTemperature: null
    },
    calibrationPosReport:{
        success: false,
        lowerStroke: '',
        lowerStrokeTolerance: ">25.0", //default
        upperStroke: '',
        upperStrokeTolerance: ">15.0", //default
        strokeHeight: '',
        strokeHeightTolerance: "55 +/- 2", //default
    },
    calibrationFreqReport: {
        success: false,
        reference: 30.0,
        measuredTime: '',
        tolerance: '-1/+2', // default
    },
    calibrationCounter: {counter: 0},
    graphOnReport: {
        export: true, //default
        print: true,  //default
    },

    //Products
    productlist: [],
    editProduct: {},

    //Methods
    methodlist: [],
    editMethod: {},

    //Reports
    reportlist: [],
    report: {},
    reportSearch: '',

    //system
    systeminfo: {
        stationVersions:[]
    },
    systemsettings: {
        companyLogo: '',
        companyName: '',
        date: '01.01.2021', //default
        dateFormat: 'DD.MM.YYYY hh:mm:ss',  //default
        language: 'English', //default
        time: '00:00:00', //default
        timeformat: '24h', //default
        timeZone: 'UTC+01:00' //default
    },
    filelist: [],
    firmwarelist: {
        currentFirmwareVersion: '',
        newFirmwareVersion: '',
        usbAwailable: true //default
    },
    firmwareprogress: {
        activity: "update loading...",
        progress: 0
    },
    printer: {
        type: '', //default
        adress: '', //default
        printerDriver: '', //default
        paper: 'A4', //default
        printProductTest: false, //default
        printQuickTest: false, //default
        printCalAdj: false, //default
        printGraph: true, //default
    },
    heaterlist: {
        0: {
            defaultTemperature: "37", //default
            testStart: 1, //default
            testStartOnFix: "37", //default
            offWhenFinished: true //default
        },
        1: {
            defaultTemperature: "37", //default
            testStart: 1, //default
            testStartOnFix: "37", //default
            offWhenFinished: true //default
        },
        2: {
            defaultTemperature: "37", //default
            testStart: 1, //default
            testStartOnFix: "37", //default
            offWhenFinished: true //default
        },
        4: {
            defaultTemperature: "37", //default
            testStart: 1, //default
            testStartOnFix: "37", //default
            offWhenFinished: true //default
        }
    },
    heaterSettings: {},
    network: {
        connected: false, //default
        dhcp: false, //default
        hostname: '',
        adress: '',
        gateway: '',
        subnet: '',
    },
    pdfExport: {
        pathId: null,
        automaticReportProductTest: false, //default
        automaticReportQuickTest: false, //default
        automaticReportAdjCal: false, //default
        exportGraph: true, //default
    },
    service:{
        tracing: false,
        logAvailable: true,
    },
    backupSettings: {
        automaticBackup: false, //default
        interval: null, //default
        pathId: null,
    },
    backuplist: [],
    pathlist: [],
    shareEdit: {},
    calSettings: {
        temperatureTolerance: "0.2", //default
        discHeightTolerance: "0.2", //default
        calibrationIntervalStatus: false, //default
        calibrationInterval: {
            height: "1", //default
            temperature: "1", //default
            position: "1", //default
            frequency: "1", //default
        }
    },
    licenselist: [],

    //Media
    medialist: [],
    editMedia: {},

    //user
    userlist: [],
    editUser: {},

    //group
    grouplist: [],
    editGroup: {},

    //security
    password: null,
    passwordrepeat: null,
    oldpassword: null,
    login: {
        user: null,
        password: ''
    },

    //audit trail
    audittraillist: [],
    auditSearch: ''
}

export default stateJS;
