import Vue from 'vue';
import Vuex from 'vuex';
import router from '../router/router';
import axios from "axios";
import stateJS from '../store/state.js';
import i18n from '../i18n';

axios.defaults.baseURL = 'https://disi-api.aesc.ch/v1'

Vue.use(Vuex);

export default new Vuex.Store({
    state: stateJS,

    getters: {
        userRights(state){
            let userRights;
            if (state.activeUser.groupId){
                let group = state.grouplist.find(group =>{
                    return group.id == state.activeUser.groupId;
                });
                if (group){
                    userRights = group.groupPermissions;
                }
            }
            return userRights;
            // if (userRights){  //for disabling userRights
            //     return userRights;
            // } else {
            //     return ['systemSettings', 'systemSettingsEdit', 'system', 'systemAudit', 'systemAuditExport']
            // }
        },
        activeMedia(state){
            let activeMedia = [];
            state.medialist.forEach(media =>{
                if (media.status != false){
                    activeMedia.push(media);
                }
            });
            return activeMedia;
        },
        activeMethods(state){
            let activeMethods = [];
            state.methodlist.forEach(method =>{
                if (method.status != false){
                    activeMethods.push(method);
                }
            });
            return activeMethods;
        },
        activeProductNames(state){
            let activeProducts = [];
            state.productlist.forEach(product =>{
                if (product.status != false){
                    activeProducts.push(product.productName);
                }
            });
            return activeProducts;
        },
        activeProductNamesWithNumber(state){
            let activeProductsWithNumber = [];
            state.productlist.forEach(product =>{
                if (product.status != false){
                    let str = '';
                    if (product.number){
                        str = `${product.productName} (${product.number})`;
                    } else {
                        str = product.productName;
                    }
                    activeProductsWithNumber.push(str);
                }
            });
            return activeProductsWithNumber;
        },
        activeGroups(state){
            let activeGroups = [];
            state.grouplist.forEach(group =>{
                if (group.status != false){
                    activeGroups.push(group);
                }
            });
            return activeGroups;
        },
    },

    mutations: {
        /**
         * set displayed language
         * @param state
         * @param lang
         */
        setLanguage (state, lang){ state.language = lang},

        /**
         * change keyboard-language of simple-keyboard
         * @param state
         * @param keyboardLang
         */
        setKeyboard (state, keyboardLang){ state.keyboardLang = keyboardLang},

        /**
         * after login set user and userData like user rights
         * @param state
         * @param userData
         */
        setLogin (state, userData){
            //set active user
            state.activeUser = userData;
            localStorage.token = userData.accessToken;
            //add username
            state.activeUser.username = state.login.user;
        },

        /**
         * delete token in localStorage and go to login-page
         */
        doLogout (){
            localStorage.removeItem('token');
            router.push({path: 'login'});
        },

        /**
         * set userToken of local Storage to store
         * @param state
         */
        setToken (state) {
            state.activeUser.accessToken = localStorage.token
        },

        /**
         * reset login form fields
         * @param state
         */
        resetLogin (state){
            //empty login form
            state.login.user = null;
            state.login.password = '';
        },

        /**
         * delete active user
         * @param state
         */
        removeActiveUser (state){
            state.activeUser = {};
        },

        /**
         * set new error message and show error screen
         * @param state
         * @param errorMessage
         */
        newError (state, errorMessage){
            state.errorScreen.message = errorMessage;
            state.errorScreen.status = true;
        },

        /**
         * set new error message and show error screen, Station turns red. When error is exited, Station turns green again.
         * @param state
         * @param errorMessage
         */
        newErrorStationRed (state, errorData){
            state.errorScreen.message = errorData.errorMessage;
            if (errorData.stationId){
                state.errorScreen.erroredStationId = errorData.stationId;
            }
            state.errorScreen.status = true;
            state.errorScreen.stationRed = true;
        },

        /**
         * hide error screen and remove error message, after this go back one page
         * @param state
         */
        exitError (state){
            state.errorScreen.message = '';
            state.errorScreen.status = false;
            if (state.errorScreen.stationRed === true){
                state.errorScreen.stationRed = false;
            }
            if (state.errorScreen.erroredStationId != null){
                state.errorScreen.erroredStationId = null;
            }
        },

        /**
         * link switch on/off to correct parameter in store
         * @param state
         * @param name
         */
        changeSwitch (state, name){
            let pathParts = name.split('-');
            if (pathParts.length == 1){
                state[pathParts[0]] = !state[pathParts[0]];
            } else if (pathParts.length == 2){
                state[pathParts[0]][pathParts[1]] = !state[pathParts[0]][pathParts[1]];
            } else if (pathParts.length == 3){
                state[pathParts[0]][pathParts[1]][pathParts[2]] = !state[pathParts[0]][pathParts[1]][pathParts[2]];
            } else if (pathParts.length == 4){
                state[pathParts[0]][pathParts[1]][pathParts[2]][pathParts[3]] = !state[pathParts[0]][pathParts[1]][pathParts[2]][pathParts[3]];
            }
        },

        /**
         * put textinput via simple-keyboard into correct parameter in store
         * @param state
         * @param storeInput
         */
        textinput (state, storeInput){
            let pathParts = storeInput.name.split('-');
            if (pathParts.length == 1){
                state[pathParts[0]] = storeInput.value;
            } else if (pathParts.length == 2){
                state[pathParts[0]][pathParts[1]] = storeInput.value;
            } else if (pathParts.length == 3){
                state[pathParts[0]][pathParts[1]][pathParts[2]] = storeInput.value;
            }
        },

        /**
         * delete last character of textinput when backspace is klicked on physical keyboard (for testing with selenium ide)
         * @param state
         * @param inputName
         */
        textDeleteKeyboard(state, inputName){
            let pathParts = inputName.split('-');
            if (pathParts.length == 1){
                let str = state[pathParts[0]];
                state[pathParts[0]] = str.substring(0, str.length - 1);
            } else if (pathParts.length == 2){
                let str = state[pathParts[0]][pathParts[1]];
                state[pathParts[0]][pathParts[1]] = str.substring(0, str.length - 1);
            } else if (pathParts.length == 3){
                let str = state[pathParts[0]][pathParts[1]][pathParts[2]];
                state[pathParts[0]][pathParts[1]][pathParts[2]] = str.substring(0, str.length - 1);
            }
        },

        /**
         * put textinput from Keyboard into correct parameter in store (for testing with selenium ide)
         * @param state
         * @param storeInput
         */
        textinputKeyboard (state, storeInput){
            let pathParts = storeInput.name.split('-');
            if (pathParts.length == 1){
                if (state[pathParts[0]] != null){
                    state[pathParts[0]] += storeInput.value;
                } else {
                    state[pathParts[0]] = storeInput.value;
                }
            } else if (pathParts.length == 2){
                if (state[pathParts[0]][pathParts[1]] != null){
                    state[pathParts[0]][pathParts[1]] += storeInput.value;
                } else {
                    state[pathParts[0]][pathParts[1]] = storeInput.value;
                }
            } else if (pathParts.length == 3){
                if (state[pathParts[0]][pathParts[1]][pathParts[2]] != null){
                    state[pathParts[0]][pathParts[1]][pathParts[2]] += storeInput.value;
                } else {
                    state[pathParts[0]][pathParts[1]][pathParts[2]] = storeInput.value;
                }
            }
        },

        /**
         * change active station that is displayed
         * @param state
         * @param number
         */
        changeStation (state, number){
            state.activeStationId = number;
        },

        /**
         * stop tube manually during test (if test is set to manual or without disc)
         * @param state
         * @param tubeNr
         */
        stopTube(state, tubeNr){
            state.stations[state.activeStationId - 1].runningTest.tubeList[tubeNr - 1].disintegration = 100;
        },

        /**
         * Resets QuickTest to default
         * @param state
         */
        resetQuickTest(state){
            state.quickTest = {
                batch: 0,  //default
                newBatch: '',
                testingTime: '00:00:00',
                media: 0,
                basket: 0,
                combinationTest: false, //default
                threshold: 0.3, //default
                temperatureStatus: true, //default
                temperatureMin: '35', //default
                temperatureMax: '39',//default
                fastDisintegration: false,
                withoutDisc: false,
                manual: false,
            }
        },

        /**
         * Resets ProductTest to default
         * @param state
         */
        resetProductTest(state){
            state.productTest = {
                product: null,
                method: null,
                batch: 0, //default
                newBatch: '',
            }
        },

        /**
         * Resets Adjustment Temperature for next Adjustment
         * @param state
         */
        resetAdjustmentHeight(state){
            state.adjustmentValue.discId = '';
            state.adjustmentValue.temperature = null;
            state.progressBar.progress = 0;
            state.progressBar.activity = '';
        },

        /**
         * Resets input fields for measured distances at calibration of basket position
         * @param state
         */
        resetCalibrationPosition(state){
            state.calibrationPosReport.upperStroke = null;
            state.calibrationPosReport.lowerStroke = null;
            state.calibrationPosReport.strokeHeight = null;
        },

        /**
         * resets input field for measured temperature at calibration of temperature
         * @param state
         */
        resetCalibrationTemperature(state){
            state.calibrationTempReport.referenceTemperature = null;
            state.progressBar.progress = 0;
            state.progressBar.activity = '';
        },

        /**
         * resets input field for measured time at calibration of frequency
         * @param state
         */
        resetCalibrationFrequency(state){
            state.calibrationCounter.counter = 0;
            state.calibrationFreqReport.measuredTime = '';
        },

        /**
         * resets progress
         * @param state
         */
        resetProgress(state){
            state.progressBar.activity = "";
            state.progressBar.progress = 0;
        },

        /**
         * copy clicked report to report and get infos to product, method, batch and media, format timestamps
         * @param state
         * @param report as object
         */
        changeReport (state, report){
            state.report = JSON.parse(JSON.stringify(report));

            //format time in results and statistics
            //format timestamp to 00:00:00
            function timeFormat(value){
                var hours = Math.floor(value / 3600);
                var minutes = Math.floor((value - hours*3600) / 60);
                var seconds = value % 60;

                let dHours = (hours > 9 ? hours : '0' + hours);
                let dMins = (minutes > 9 ? minutes : '0' + minutes);
                let dSecs = (seconds > 9 ? seconds : '0' + seconds);

                return dHours + ':' + dMins + ':' + dSecs
            }
            if (report.results.tube1){
                state.report.results.tube1 = timeFormat(report.results.tube1);
            } else {
                state.report.results.tube1 = '–'
            }

            if (report.results.tube2){
                state.report.results.tube2 = timeFormat(report.results.tube2);
            } else {
                state.report.results.tube2 = '–'
            }

            if (report.results.tube3){
                state.report.results.tube3 = timeFormat(report.results.tube3);
            } else {
                state.report.results.tube3 = '–'
            }

            if (report.results.tube4){
                state.report.results.tube4 = timeFormat(report.results.tube4);
            } else {
                state.report.results.tube4 = '–'
            }

            if (report.results.tube5){
                state.report.results.tube5 = timeFormat(report.results.tube5);
            } else {
                state.report.results.tube5 = '–'
            }

            if (report.results.tube6){
                state.report.results.tube6 = timeFormat(report.results.tube6);
            } else {
                state.report.results.tube6 = '–'
            }

            state.report.statistics.xMin = timeFormat(report.statistics.xMin);
            state.report.statistics.xMax = timeFormat(report.statistics.xMax);
            state.report.statistics.xDiff = timeFormat(report.statistics.xDiff);
            state.report.statistics.mean = timeFormat(report.statistics.mean);
            state.report.statistics.sabs = timeFormat(report.statistics.sabs);
            state.report.statistics.srel = timeFormat(report.statistics.srel);

            //method
            let methodID = report.method;
            if (methodID > 0){
                let method = state.methodlist.find(element =>{
                    return element.id === methodID;
                });
                if (method){
                    state.report.method = {};
                    state.report.method.methodName = method.methodName;
                    state.report.method.testingTimeTimestamp = method.testingTime;
                    state.report.method.testingTime = timeFormat(method.testingTime);
                    state.report.method.maxTestingTimeStatus = method.maxTestingTimeStatus;
                    state.report.method.maxTestingTime = timeFormat(method.maxTestingTime);
                    state.report.method.mediaID = method.media;
                    state.report.method.basket = method.basket;
                    state.report.method.threshold = method.threshold;
                    state.report.method.temperatureStatus = method.temperatureStatus;
                    state.report.method.temperatureMin = method.temperatureMin;
                    state.report.method.temperatureMax = method.temperatureMax;
                    state.report.method.pretestStatus = method.pretestStatus;
                    state.report.method.pretestMediaID = method.pretestMedia;
                    state.report.method.pretestTestingTime = timeFormat(method.pretestTestingTime);
                    state.report.method.assessmentStatus = method.assessmentStatus;
                    state.report.method.assessmentMinDisintegrated = method.assessmentMinDisintegrated;
                    state.report.method.assessmentMaxDisintegrated = method.assessmentMaxDisintegrated;
                    state.report.method.fastDisintegration = method.fastDisintegration;
                    state.report.method.withoutDisc = method.withoutDisc;
                    state.report.method.manual = method.manual;

                    //media
                    let mediaID = method.media;
                    if (mediaID > 0){
                        let media = state.medialist.find(element =>{
                            return element.id === mediaID;
                        });
                        if (media){
                            state.report.method.mediaName = media.name;
                        } else {
                            state.report.method.mediaName = 'Unknown';
                        }
                    }
                    let pretestMediaID = method.pretestMedia;
                    if (pretestMediaID > 0){
                        let pretestMedia = state.medialist.find(element =>{
                            return element.id === pretestMediaID;
                        });
                        if (pretestMedia){
                            state.report.method.pretestMediaName = pretestMedia.name;
                        } else {
                            state.report.method.pretestMediaName = 'Unknown';
                        }
                    }
                } else {
                    state.report.method = {};
                    state.report.method.methodId = report.method
                    state.report.method.methodName = 'Unknown';
                }
            }
            //get productname and batch if navigation directly from detailPage
            if (!state.report.productName){
                //Product
                let productId = report.testInfo.productId;
                let product = state.productlist.find(element =>{
                    return element.id === productId;
                });
                if (product){
                    state.report.testInfo.productName = product.productName;
                    state.report.testInfo.productNumber = product.number;
                } else {
                    state.report.testInfo.productName = 'QuickTest';
                    state.report.testInfo.productNumber = '–';
                }

                //Batch
                let batchId = report.testInfo.batchId;
                if (batchId > 0){
                    let batch = state.batchlist.find(element =>{
                        return element.id === batchId;
                    });
                    if (batch){
                        state.report.testInfo.batch = batch.name;
                    } else {
                        state.report.testInfo.batch = 'Unknown';
                    }
                }
            }
            //nav
            router.push({path: 'report-testinfo'});
        },

        /**
         * copy clicked Share to editShare for editing
         * @param state
         * @param share
         * @constructor
         */
        ChangeEditShare (state, share){
            state.shareEdit = JSON.parse(JSON.stringify(share));
            router.push({path: 'share-edit'});
        },

        SetShareEditToMounted (state){
            state.shareEdit.connectionStatus = true;
        },

        /**
         * copy clicked user to editUser for editing,
         * convert numbers to strings for simple-keyboard
         * @param state
         * @param user
         * @constructor
         */
        ChangeEditUser (state, user){
            state.editUser = JSON.parse(JSON.stringify(user));
            //make string out of numbers for keyboard
            for (const [key, value] of Object.entries(state.editUser)) {
                if (typeof value === 'number' && key != 'groupId'){
                    state.editUser[key] = value.toString();
                }
            }
            router.push({path: 'users-edit'});
        },

        /**
         * empty password and password repeat states
         * @param state
         */
        emptyPassword (state){
            state.password = null;
            state.passwordrepeat = null;
            state.oldpassword = null;
        },

        /**
         * empty companyLogo state
         * @param state
         */
        deleteLogo (state){
            state.systemsettings.companyLogo = '';
        },

        /**
         * copy clicked group to editGroup for editing,
         * convert numbers to strings for simple-keyboard,
         * format timestamp to 00:00:00 for timepicker
         * @param state
         * @param group
         * @constructor
         */
        ChangeEditGroup (state, group){
            state.editGroup = JSON.parse(JSON.stringify(group));
            //make string out of numbers for keyboard
            for (const [key, value] of Object.entries(state.editGroup)) {
                if (typeof value === 'number'){
                    state.editGroup[key] = value.toString();
                }
            }
            //format timestamp to 00:00:00 for timepicker
            function timeFormat(value){
                var hours = Math.floor(value / 3600);
                var minutes = Math.floor((value - hours*3600) / 60);
                var seconds = value % 60;

                let dHours = (hours > 9 ? hours : '0' + hours);
                let dMins = (minutes > 9 ? minutes : '0' + minutes);
                let dSecs = (seconds > 9 ? seconds : '0' + seconds);

                return dHours + ':' + dMins + ':' + dSecs
            }
            state.editGroup.autoLogoffTime = timeFormat(state.editGroup.autoLogoffTime);
            router.push({path: 'usergroups-edit'});
        },

        /**
         * copy clicked Product to editProduct for editing,
         * convert numbers to strings for simple-keyboard
         * @param state
         * @param product
         * @constructor
         */
        ChangeEditProduct (state, product){
            state.editProduct = JSON.parse(JSON.stringify(product));
            //make string out of numbers for keyboard
            for (const [key, value] of Object.entries(state.editProduct)) {
                if (typeof value === 'number' && key != 'method'){
                    state.editProduct[key] = value.toString();
                }
            }
            router.push({path: 'products-edit'});
        },

        /**
         * copy clicked Method to editMethod for editing,
         * convert numbers to strings for simple-keyboard,
         * format timestamp to 00:00:00 for timepicker
         * @param state
         * @param method
         * @constructor
         */
        ChangeEditMethod (state, method){
            state.editMethod = JSON.parse(JSON.stringify(method));

            //make string out of numbers for keyboard
            for (const [key, value] of Object.entries(state.editMethod)) {
                if (typeof value === 'number' && key != 'media' && key != 'basket' && key != 'pretestMedia'){
                    state.editMethod[key] = value.toString();
                }
            }

            //format timestamp to 00:00:00 for timepicker
            function timeFormat(value){
                var hours = Math.floor(value / 3600);
                var minutes = Math.floor((value - hours*3600) / 60);
                var seconds = value % 60;

                let dHours = (hours > 9 ? hours : '0' + hours);
                let dMins = (minutes > 9 ? minutes : '0' + minutes);
                let dSecs = (seconds > 9 ? seconds : '0' + seconds);

                return dHours + ':' + dMins + ':' + dSecs
            }
            state.editMethod.testingTime = timeFormat(state.editMethod.testingTime);
            state.editMethod.maxTestingTime = timeFormat(state.editMethod.maxTestingTime);
            state.editMethod.pretestTestingTime = timeFormat(state.editMethod.pretestTestingTime);

            router.push({path: 'methods-edit'});
        },

        /**
         * set combitest to OFF
         * @param state
         * @param key in state
         */
        setCombiTestOff (state, key){
            state[key].combinationTest = false;
        },

        /**
         * set fast disintigration to OFF
         * @param state
         * @param key in state
         */
        setFastDisintigrationOff (state, key){
            state[key].fastDisintegration = false;
        },

        /**
         * copy clicked Media to EditMedia for editing,
         * convert numbers to strings for simple-keyboard,
         * @param state
         * @param media
         * @constructor
         */
        ChangeEditMedia (state, media){
            state.editMedia = JSON.parse(JSON.stringify(media));
            //make string out of numbers for keyboard
            for (const [key, value] of Object.entries(state.editMedia)) {
                if (typeof value === 'number'){
                    state.editMedia[key] = value.toString();
                }
            }
            router.push({path: 'media-edit'});
        },

        /**
         * change data if station navigation is clicked at heater settings
         * @param state
         */
        changeHeater(state){
            let activeStation = state.heaterlist.find(station => {
                return station.stationId === state.activeStationId;
            });
            state.heaterSettings = JSON.parse(JSON.stringify(activeStation));
            //make string out of numbers for keyboard
            for (const [key, value] of Object.entries(state.heaterSettings)) {
                if (typeof value === 'number' && key != 'testStart'){
                    state.heaterSettings[key] = value.toString();
                }
            }
        },

        /**
         * QuickTest: update batch selection in dropdown
         * @param state
         * @param batchName
         */
        quickTestUpdateBatch (state, batchName){ state.quickTest.batch = batchName },

        /**
         * ProductTest: update product selection in dropdown
         * @param state
         * @param productId
         */
        updateProduct (state, productId){ state.productTest.product = productId },

        /**
         * ProductTest: update method selection when selecting product dropdown
         * @param state
         * @param methodID
         */
        updateMethod (state, methodID){ state.productTest.method = methodID },

        /**
         *
          * @param state
         * @param payload
         * payload[0] = state
         * payload[1] = data
         */
        setGraphOnReport (state, payload){
            state.graphOnReport[payload[0]] = payload[1];
        },


        /**
         * general setter for axios calls.
         *
         * @param state
         * @param payload
         * payload[0] = state
         * payload[1] = data
         */
        setData (state, payload){
            state[payload[0]] = payload[1];

            //make string out of numbers for keyboard
            //backupSettings
            for (const [key, value] of Object.entries(state[payload[0]])) {
                if (typeof value === 'number' && key === 'interval'){
                    state.backupSettings.interval = value.toString();
                }
            }
            //calSettings
            if (payload[0] == 'calSettings'){
                for (const [key, value] of Object.entries(state[payload[0]])) {
                    if (typeof value === 'number' && key != 'calibrationIntervalStatus'){
                        state.calSettings[key] = value.toString();
                    }
                    //check object in object
                    if (key == 'calibrationInterval'){
                        for (const [subkey, subvalue] of Object.entries(value)) {
                            if (typeof subvalue === 'number'){
                                state.calSettings.calibrationInterval[subkey] = subvalue.toString();
                            }
                        }
                    }
                }
            }
        },

        /**
         * AuditTrail: empty AuditTrail list
         * @param state
         */
        emptyAuditTrailList (state){ state.audittraillist = [] },

        /**
         * empty auditSearch state
         * @param state
         */
        emptyAuditSearch (state){
            state.auditSearch = '';
        },
    },


    actions: {

        /**
         * get call REST, response.data set to local state
         *
         * @param commit
         * @param payload
         * payload[0] = url
         * payload[1] = parameters
         *
         * @returns response.data or error
         */
        getAxiosSetter({commit, state}, payload){
            return axios.get(payload[0], {headers: {authorization: "Bearer "+state.activeUser.accessToken}})
                .then(response => {
                    if (response.data){
                        let toSet = [payload[1], response.data]
                        commit('setData', toSet);
                    }
                    return response;
                })
                .catch(error => {
                    console.error(error);
                    if (error.response && error.response.data && error.response.data.errorCode == 401){
                        console.error(401);
                        commit('doLogout');
                    } else if (error.response && error.response.data && error.response.data.errorCode == 213){
                        console.error(213);
                        commit('newErrorStationRed', error.response.data);
                    } else if (error.response && error.response.data && error.response.data.errorMessage){
                        commit('newError', error.response.data.errorMessage);
                    } else {
                        commit('newError', i18n.t('alerts.somethingWentWrong'));
                    }
                    return error;
                })
        },

        /**
         * get call REST, response.data set to local state, no accessToken required
         *
         * @param commit
         * @param payload
         * payload[0] = url
         * payload[1] = parameters
         *
         * @returns response.data or error
         */
        getAxiosSetterNoToken({commit}, payload){
            return axios.get(payload[0])
                .then(response => {
                    if (response.data){
                        let toSet = [payload[1], response.data]
                        commit('setData', toSet);
                    }
                    return response;
                })
                .catch(error => {
                    console.error(error);
                    if (error.response && error.response.data && error.response.data.errorCode == 401){
                        console.error(401);
                        commit('doLogout');
                    } else if (error.response && error.response.data && error.response.data.errorCode == 213){
                        console.error(213);
                        commit('newErrorStationRed', error.response.data);
                    } else if (error.response && error.response.data && error.response.data.errorMessage){
                        commit('newError', error.response.data.errorMessage);
                    } else {
                        commit('newError', i18n.t('alerts.somethingWentWrong'));
                    }
                    return error;
                })
        },

        /**
         * get call REST without setting local state
         * @param url
         * @returns response or error
         */
        getAxiosNoSetter({commit, state}, url){
            return axios.get(url, {headers: {authorization: "Bearer "+state.activeUser.accessToken}})
                .then(response => {
                    return response;
                })
                .catch(error => {
                    console.error(error);
                    if (error.response && error.response.data && error.response.data.errorCode == 401){
                        console.error(401);
                        commit('doLogout');
                    } else if (error.response && error.response.data && error.response.data.errorCode == 213){
                        console.error(213);
                        commit('newErrorStationRed', error.response.data);
                    } else if (error.response && error.response.data && error.response.data.errorMessage){
                        commit('newError', error.response.data.errorMessage);
                    } else {
                        commit('newError', i18n.t('alerts.somethingWentWrong'));
                    }
                    return error;
                })
        },

        /**
         * POST REST
         * @param payload
         * payload[0] = url
         * payload[1] = parameters
         *
         * @returns response or error
         */
        postAxiosNoSetter({commit, state}, payload){
            return axios.post(payload[0], payload[1], {headers: {authorization: "Bearer "+state.activeUser.accessToken}})
                .then(response => {
                    return response;
                })
                .catch(error => {
                    console.error(error);
                    if (error.response && error.response.data && error.response.data.errorCode == 401){
                        console.error(401);
                        commit('doLogout');
                    } else if (error.response && error.response.data && error.response.data.errorCode == 213){
                        console.error(213);
                        commit('newErrorStationRed', error.response.data);
                    } else if (error.response && error.response.data && error.response.data.errorMessage){
                        commit('newError', error.response.data.errorMessage);
                    } else {
                        commit('newError', i18n.t('alerts.somethingWentWrong'));
                    }
                    return error;
                })
        },

        /**
         * POST REST WITH LOCAL SETTER
         *
         * @param payload
         * payload[0] = url
         * payload[1] = parameters
         * payload[2] = local state
         * payload[3] = mustBeArray
         * @returns response or error
         */
        postAxiosSetter({commit, state}, payload){
            return axios.post(payload[0], payload[1], {headers: {authorization: "Bearer "+state.activeUser.accessToken}})
                .then(response => {
                    if (response.data){
                        let dataToSet = response.data;
                        if (payload[3] === true){
                            if (Array.isArray(dataToSet) === false){
                                dataToSet = [response.data];
                            }
                        }
                        let toSet = [payload[2], dataToSet]
                        commit('setData', toSet);
                    }
                    return response;
                })
                .catch(error => {
                    console.error(error);
                    if (error.response && error.response.data && error.response.data.errorCode == 401){
                        console.error(401);
                        commit('doLogout');
                    } else if (error.response && error.response.data && error.response.data.errorCode == 213){
                        console.error(213);
                        commit('newErrorStationRed', error.response.data);
                    } else if (error.response && error.response.data && error.response.data.errorMessage){
                        commit('newError', error.response.data.errorMessage);
                    } else {
                        commit('newError', i18n.t('alerts.somethingWentWrong'));
                    }
                    return error;
                })
        },

        /**
         * POST REST WITH LOCAL SETTER AND CANCEL OPTION
         *
         * @param payload
         * payload[0] = url
         * payload[1] = parameters
         * payload[2] = local state
         *
         * @returns response or error
         */
        postAxiosCancelSetter({commit, state}, payload){
            if(state.cancelToken == undefined){
                const CancelToken = axios.CancelToken;
                state.cancelToken = CancelToken.source();
            }
            return axios.post(payload[0], payload[1], {headers: {authorization: "Bearer "+state.activeUser.accessToken}, cancelToken: state.cancelToken.token})
                .then(response => {
                    if (response.data){
                        let toSet = [payload[2], response.data]
                        commit('setData', toSet);
                    }
                    return response;
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        console.log('Request canceled', error.message);
                    } else {
                        console.error(error);
                        if (error.response && error.response.data && error.response.data.errorCode == 401) {
                            console.error(401);
                            commit('doLogout');
                        } else if (error.response && error.response.data && error.response.data.errorCode == 213) {
                            console.error(213);
                            commit('newErrorStationRed', error.response.data);
                        } else if (error.response && error.response.data && error.response.data.errorMessage) {
                            commit('newError', error.response.data.errorMessage);
                        } else {
                            commit('newError', i18n.t('alerts.somethingWentWrong'));
                        }
                    }
                    return error;
                })
        },

        doLogin({commit}, login){
            return axios.post('admin/user/login', login)
                .then(response => {
                    commit('setLogin', response.data);
                    commit('resetLogin');
                    return response;
                })
                .catch(error => {
                    console.error(error);
                    if (error.response && error.response.data && error.response.data.errorMessage){
                        commit('newError', error.response.data.errorMessage);
                        commit('doLogout');
                    } else {
                        commit('newError', i18n.t('alerts.somethingWentWrong'));
                    }
                    return error;
                })
        },
    }
});