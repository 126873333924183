<template>
  <div v-if="errorMessage" class="Error d-flex align-center justify-center" @click="errorExitClicked">
      <div class="cancel" v-if="!hideX"></div>
      <div class="message">
          <p>{{errorMessage}}</p>
          <p class="pt-4 tapScreen" v-if="tapScreen">{{ $t('error.tapScreenToContinue') }}</p>
      </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Error",
    props: {
        errorMessage: {
            type: String,
        },
        hideX:{
            type: Boolean,
            default: false
        },
        tapScreen: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        ...mapState([
            'error',
            'errorScreen',
        ]),
    },
    methods: {
        errorExitClicked () {
            if (this.errorScreen.stationRed){
                this.$store.dispatch('postAxiosNoSetter', ['/admin/stations/clearError', {id: this.errorScreen.erroredStationId}]);
            }
            this.$store.commit('exitError');
        }
    }
}
</script>

<style scoped lang="scss">
.v-application .Error{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 15;
    background-color: rgba($disiDarkblueLogo, 0.9);
    border: 10px solid $disiRed;

    .cancel{
        position: absolute;
        top: -10px;
        right: -10px;
        width: 60px;
        height: 60px;
        background-color: $disiIrisblue;
        background-image: url("../assets/cancel_weiss.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 45%;
    }

    .message p{
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1.8rem;
        color: $disiRed;
        text-align: center;
        padding: 0 60px;

        &.tapScreen{
            color: white;
            font-size: 1rem;
        }
    }
}
</style>