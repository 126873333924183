import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store/store";
import SplashScreen from '../views/SplashScreen.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: SplashScreen
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
    },
    {
        path: '/change-password',
        name: 'ChangePassword',
        component: () => import(/* webpackChunkName: "ChangePassword" */ '../views/ChangePassword.vue')
    },
    {
        path: '/stations-overview',
        name: 'StationsOverview',
        component: () => import(/* webpackChunkName: "StationsOverview" */ '../views/StationsOverview.vue')
    },
    {
        path: '/station-testdetail',
        name: 'StationTestDetail',
        component: () => import(/* webpackChunkName: "StationTestDetail" */ '../views/StationTestDetail.vue')
    },
    {
        path: '/quick-test',
        name: 'QuickTest',
        component: () => import(/* webpackChunkName: "QuickTest" */ '../views/QuickTest.vue')
    },
    {
        path: '/product-test',
        name: 'ProductTest',
        component: () => import(/* webpackChunkName: "ProductTest" */ '../views/ProductTest.vue')
    },
    {
        path: '/adj-cal',
        name: 'AdjCal',
        component: () => import(/* webpackChunkName: "AdjCal" */ '../views/AdjCal.vue')
    },
    {
        path: '/adjustment',
        name: 'Adjustment',
        component: () => import(/* webpackChunkName: "Adjustment" */ '../views/Adjustment.vue')
    },
    {
        path: '/adjustment-reports',
        name: 'AdjustmentReports',
        component: () => import(/* webpackChunkName: "AdjustmentReports" */ '../views/AdjustmentReports.vue')
    },
    {
        path: '/adjustment-height',
        name: 'AdjustmentHeight',
        component: () => import(/* webpackChunkName: "AdjustmentHeight" */ '../views/AdjustmentHeight.vue')
    },
    {
        path: '/adjustment-position',
        name: 'AdjustmentPosition',
        component: () => import(/* webpackChunkName: "AdjustmentPosition" */ '../views/AdjustmentPosition.vue')
    },
    {
        path: '/calibration',
        name: 'Calibration',
        component: () => import(/* webpackChunkName: "Calibration" */ '../views/Calibration.vue')
    },
    {
        path: '/calibration-reports',
        name: 'CalibrationReports',
        component: () => import(/* webpackChunkName: "CalibrationReports" */ '../views/CalibrationReports.vue')
    },
    {
        path: '/calibration-height',
        name: 'CalibrationHeight',
        component: () => import(/* webpackChunkName: "CalibrationHeight" */ '../views/CalibrationHeight.vue')
    },
    {
        path: '/calibration-temp',
        name: 'CalibrationTemp',
        component: () => import(/* webpackChunkName: "CalibrationTemp" */ '../views/CalibrationTemp.vue')
    },
    {
        path: '/calibration-position',
        name: 'CalibrationPosition',
        component: () => import(/* webpackChunkName: "CalibrationPosition" */ '../views/CalibrationPosition.vue')
    },
    {
        path: '/calibration-freq',
        name: 'CalibrationFreq',
        component: () => import(/* webpackChunkName: "CalibrationFreq" */ '../views/CalibrationFreq.vue')
    },
    {
        path: '/methods',
        name: 'Methods',
        component: () => import(/* webpackChunkName: "Methods" */ '../views/Methods.vue')
    },
    {
        path: '/methods-edit',
        name: 'MethodsEdit',
        component: () => import(/* webpackChunkName: "MethodsEdit" */ '../views/MethodsEdit.vue')
    },
    {
        path: '/products',
        name: 'Products',
        component: () => import(/* webpackChunkName: "Products" */ '../views/Products.vue')
    },
    {
        path: '/products-edit',
        name: 'ProductsEdit',
        component: () => import(/* webpackChunkName: "ProductsEdit" */ '../views/ProductsEdit.vue')
    },
    {
        path: '/report',
        name: 'Report',
        component: () => import(/* webpackChunkName: "Report" */ '../views/Report.vue')
    },
    {
        path: '/report-testinfo',
        name: 'ReportTestinfo',
        component: () => import(/* webpackChunkName: "ReportTestinfo" */ '../views/ReportTestinfo.vue')
    },
    {
        path: '/report-method',
        name: 'ReportMethod',
        component: () => import(/* webpackChunkName: "ReportMethod" */ '../views/ReportMethod.vue')
    },
    {
        path: '/report-results',
        name: 'ReportResults',
        component: () => import(/* webpackChunkName: "ReportResults" */ '../views/ReportResults.vue')
    },
    {
        path: '/report-statistics',
        name: 'ReportStatistics',
        component: () => import(/* webpackChunkName: "ReportStatistics" */ '../views/ReportStatistics.vue')
    },
    {
        path: '/report-graph',
        name: 'ReportGraph',
        component: () => import(/* webpackChunkName: "ReportGraph" */ '../views/ReportGraph.vue')
    },
    {
        path: '/system',
        name: 'System',
        component: () => import(/* webpackChunkName: "System" */ '../views/System.vue')
    },
    {
        path: '/calsettings',
        name: 'CalSettings',
        component: () => import(/* webpackChunkName: "CalSettings" */ '../views/CalSettings.vue')
    },
    {
        path: '/media',
        name: 'Media',
        component: () => import(/* webpackChunkName: "Media" */ '../views/Media.vue')
    },
    {
        path: '/media-edit',
        name: 'MediaEdit',
        component: () => import(/* webpackChunkName: "MediaEdit" */ '../views/MediaEdit.vue')
    },
    {
        path: '/printer',
        name: 'Printer',
        component: () => import(/* webpackChunkName: "Printer" */ '../views/Printer.vue')
    },
    {
        path: '/pdf-export',
        name: 'PDFExport',
        component: () => import(/* webpackChunkName: "PDFExport" */ '../views/PDFExport.vue')
    },
    {
        path: '/stations',
        name: 'Stations',
        component: () => import(/* webpackChunkName: "Stations" */ '../views/Stations.vue')
    },
    {
        path: '/backup',
        name: 'Backup',
        component: () => import(/* webpackChunkName: "Backup" */ '../views/Backup.vue')
    },
    {
        path: '/backup-restore',
        name: 'BackupRestore',
        component: () => import(/* webpackChunkName: "BackupRestore" */ '../views/BackupRestore.vue')
    },
    {
        path: '/user-management',
        name: 'UserManagement',
        component: () => import(/* webpackChunkName: "UserManagement" */ '../views/UserManagement.vue')
    },
    {
        path: '/users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "Users" */ '../views/Users.vue')
    },
    {
        path: '/users-edit',
        name: 'UsersEdit',
        component: () => import(/* webpackChunkName: "UsersEdit" */ '../views/UsersEdit.vue')
    },
    {
        path: '/usergroups',
        name: 'UserGroups',
        component: () => import(/* webpackChunkName: "UserGroups" */ '../views/UserGroups.vue')
    },
    {
        path: '/usergroups-edit',
        name: 'UserGroupsEdit',
        component: () => import(/* webpackChunkName: "UserGroupsEdit" */ '../views/UserGroupsEdit.vue')
    },
    {
        path: '/auditTrail',
        name: 'AuditTrail',
        component: () => import(/* webpackChunkName: "AuditTrail" */ '../views/AuditTrail.vue')
    },
    {
        path: '/service',
        name: 'Service',
        component: () => import(/* webpackChunkName: "Service" */ '../views/Service.vue')
    },
    {
        path: '/network',
        name: 'Network',
        component: () => import(/* webpackChunkName: "Network" */ '../views/Network.vue')
    },
    {
        path: '/share',
        name: 'Share',
        component: () => import(/* webpackChunkName: "Share" */ '../views/Share.vue')
    },
    {
        path: '/share-edit',
        name: 'ShareEdit',
        component: () => import(/* webpackChunkName: "ShareEdit" */ '../views/ShareEdit.vue')
    },
    {
        path: '/firmwareupdate',
        name: 'Firmwareupdate',
        component: () => import(/* webpackChunkName: "Firmwareupdate" */ '../views/Firmwareupdate.vue')
    },
    {
        path: '/system-settings',
        name: 'SystemSettings',
        component: () => import(/* webpackChunkName: "SystemSettings" */ '../views/SystemSettings.vue')
    },
    {
        path: '/system-info',
        name: 'SystemInfo',
        component: () => import(/* webpackChunkName: "SystemInfo" */ '../views/SystemInfo.vue')
    },
    {
        path: '/heater',
        name: 'Heater',
        component: () => import(/* webpackChunkName: "Heater" */ '../views/Heater.vue')
    },
    {
        path: '*',
        name: 'Error404',
        component: () => import(/* webpackChunkName: "Error404" */ '../views/Error404.vue')
    }
]


const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    if(store.state.errorScreen.status){
        return false;
    } else {
        next();
    }
})

export default router
