<template>
    <transition
        name="fade"
        mode="out-in"
    >
        <div class="UnsavedChanges" v-show="content.show">
            <div class="message">
                <p>{{ content.message }}</p>
                <div v-if="!content.okButton" class="button-container mt-8">
                    <v-btn elevation="0" class="content-btn small confirmClose" @click="close">{{content.cancelText}}</v-btn>
                    <v-btn elevation="0" class="content-btn small confirmOK" @click="ok">{{content.continueText}}</v-btn>
                </div>
                <div v-else class="button-container okButton mt-8">
                    <v-btn elevation="0" class="content-btn small" @click="close"> OK </v-btn>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    data() {
        return {
            // Pop-up content
            isShow: true,
            content: {
                message: "",
                data: "",
                show: false,
                cancelText: 'cancel',
                continueText: 'continue',
                okButton: false,
            }
        };
    },
    methods: {
        close() {
        },
        ok() {
        }
    }
};
</script>

<style scoped lang="scss">
.UnsavedChanges{
    position: absolute;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: rgba($disiDarkblue, 0.9);
    border: 10px solid $disiIrisblue;
    font-family: "Gilroy", sans-serif;

    .message{
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .message p{
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1.8rem;
        color: white;
        text-align: center;
        padding: 0 60px;
    }

    .button-container{
        min-width: 40%;
        display: flex;
        justify-content: space-between;
        margin-top: 32px;

        &.okButton{
            justify-content: center;
        }

        .content-btn{
            padding-top: 3px;
        }
    }
}
</style>
