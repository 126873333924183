// Leicht abgeändert nach https://www.programmersought.com/article/38165272962/

import Vue from 'vue';
import confirm from '../components/Confirm';


let confirmConstructor = Vue.extend(confirm);
let theConfirm = function (content) {
    return new Promise((res, rej) => {
        //Promise encapsulation, ok executes resolve, no executes rejectlet
        let confirmDom = new confirmConstructor({
            el: document.createElement('div')
        })
        document.querySelector('.v-application').appendChild(confirmDom.$el); //new an object, then insert it into the body
        confirmDom.content = content; //In order to make the confirm more scalable, this is passed in as an object, and all fields can be customized according to requirements
        confirmDom.ok = function () {
            res();
            confirmDom.content.show = false;
        }
        confirmDom.close = function () {
            rej();
            confirmDom.content.show = false;
        }

    })
}
export default theConfirm;
