<template>
    <div class="SplashScreen full-height pa-4 d-flex flex-column justify-center align-center">
        <div class="container full-height pa-4 d-flex flex-column">
            <img src="../assets/Ischi-logo-weiss-22.svg" alt="Charles Ischi AG Logo" class="logo">
            <v-spacer></v-spacer>
            <h1>{{$t('splashScreen.welcome')}}</h1>
            <p>{{$t('splashScreen.subline')}}</p>
        </div>
    </div>
</template>

<script>

import {mapState} from "vuex";

export default {
    name: 'SplashScreen',
    components: {
    },
    props: {
    },
    data () {
        return {
        }
    },
    created(){
        setTimeout( () => this.$router.push({ path: '/login'}), 4000);
    },
    computed: {
        ...mapState([
            'systemsettings',
            'language'
        ]),
    },
    methods:{
    },
}
</script>
<style scoped lang="scss">
.SplashScreen{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $disiDarkblue;
    z-index: 2000;
    .container{
        max-height: 80%;
    }
    .logo{
        max-width: 70%;
        margin: 0 auto;
    }
    h1{
        color: white;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
        padding-bottom: 12px;
    }
    p{
        color: white;
        font-size: 1.3rem;
        text-align: center;
    }
}
</style>