<template>
    <v-app :class="{'keyboardHeight': isKeyboardOpen}">
        <Header></Header>
        <v-main>
            <error v-if="errorScreen.status" :error-message="errorScreen.message"></error>
            <info v-if="showInfo" :info-message="infoMessage" :success="showSuccessIcon" @infoExitClicked="infoExit"></info>
            <transition
                name="fade"
                mode="out-in"
            >
                <router-view
                    @newSuccess="newSuccess"
                    @onInputFocus="onInputFocus"
                    @resetInput="resetInput"
                    :class="{'keyboardHeight': isKeyboardOpen}"
                />
            </transition>
            <v-slide-y-reverse-transition>
                <simple-keyboard ref="keyboard" :onlyNumbers="onlyNumbers" :inputName="inputName" v-show="isKeyboardOpen" @onKeyPress="onKeyPress" v-click-outside="closeKeyboard"/>
            </v-slide-y-reverse-transition>
        </v-main>
    </v-app>
</template>


<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Error from "@/components/Error";
import Info from "@/components/Info";
import SimpleKeyboard from "@/components/SimpleKeyboard";
import {mapState} from "vuex";

export default {
    name: 'Home',
    components: {
        Header,
        Error,
        Info,
        SimpleKeyboard,
    },
    data () {
        return {
            //info / success
            showInfo: false,
            infoMessage: '',
            showSuccessIcon: false,
            //keyboard
            isKeyboardOpen: false,
            inputName: '',
            onlyNumbers: false,
        }
    },
    computed: {
        ...mapState([
            'errorScreen',
        ]),
    },
    methods: {
        // info / success ------------------ //
        newSuccess(){
            this.infoMessage = this.$t('general.success');
            this.showSuccessIcon = true;
            this.showInfo = true;
        },
        infoExit(){
            this.showInfo = false;
            this.infoMessage = '';
            this.showSuccessIcon = false;
        },
        // Keyboard functions ------------------ //
        onInputFocus(input) {
            this.onlyNumbers = input[1];
            this.inputName = input[0];
            this.isKeyboardOpen = true;
        },
        onKeyPress(button) {
            if (button === "{enter}") {
                this.isKeyboardOpen = false;
            }
        },
        closeKeyboard() {
            let focused = document.querySelector('.v-input--is-focused.v-text-field:not(.v-select):not(.v-input--is-readonly)');
            if (focused){
                this.isKeyboardOpen = true;
            } else {
                this.isKeyboardOpen = false;
            }
        },
        resetInput(){
            this.$refs.keyboard.resetInput();
        },
    },
    created() {
        //watch for hit enter to hide Keyboard
        this.$root.$on('hideKeyboard', () => {
            this.isKeyboardOpen = false;
        })
    },
    mounted() {
        if(localStorage.token){
            this.$store.commit('setToken');
        }
    },
    beforeDestroy () {
        this.$root.$off('hideKeyboard')
    }
}
</script>

<style lang="scss">
//Page Transition
.fade-enter-active,
.fade-leave-active {
    transition-duration: 0.1s;
    transition-property: opacity;
    transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
}
</style>
