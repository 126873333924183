<template>
  <div v-if="infoMessage || infoIcon" class="Info d-flex align-center justify-center" :class="{'successGreen': success}" @click="infoExitClicked">
      <div class="cancel" v-if="!hideX"></div>
      <div class="message">
          <div v-if="infoIcon" class="infoIcon"></div>
          <p v-if="infoMessage"><span class="successIcon" v-if="success"></span>{{infoMessage}}</p>
          <p class="pt-4 tapScreen" v-if="tapScreen">{{ $t('error.tapScreenToContinue') }}</p>
      </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Info",
    props: {
        infoMessage: {
            type: String,
        },
        hideX:{
            type: Boolean,
            default: false
        },
        tapScreen: {
            type: Boolean,
            default: false
        },
        success: {
            type: Boolean,
            default: false
        },
        infoIcon: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        ...mapState([
        ]),
    },
    methods: {
        infoExitClicked () {
            this.$emit('infoExitClicked');
        }
    }
}
</script>

<style scoped lang="scss">
.v-application .Info{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: rgba($disiDarkblue, 0.9);
    border: 10px solid $disiIrisblue;

    .cancel{
        position: absolute;
        top: -10px;
        right: -10px;
        width: 60px;
        height: 60px;
        background-color: $disiIrisblue;
        background-image: url("../assets/cancel_weiss.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 45%;
    }

    .message .successIcon{
        display: inline-block;
        margin-right: 20px ;
        width: 40px;
        height: 25px;
        background-image: url("../assets/OK_irisblue.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
    }

    .message .infoIcon{
        display: inline-block;
        margin-right: 20px ;
        width: 40px;
        height: 40px;
        background-image: url("../assets/info_irisblue.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .message p{
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1.8rem;
        color: white;
        text-align: center;
        padding: 0 60px;

        &.tapScreen{
            color: white;
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }

    &.successGreen{
        border: 10px solid $disiIrisblue;

        .cancel{
            background-color: $disiIrisblue;
        }
    }
}
</style>